
import React, {useContext, useState} from "react";
import {Image, Box, Button, Menu, Anchor, ResponsiveContext } from "grommet";
import {Gremlin} from "grommet-icons";
import styled from 'styled-components';
import { ReactComponent as AppBarLogo } from "../../images/svgs/logo.svg";
// import appBarLogo from "../../images/svgs/logo.svg";


import options from "../../options";

// const MenuItem = ({label, icon}) => {
//   return (
//     <Box flex direction="row" pad="small" gap="small">
//       {icon}{label}
//     </Box>
//   );
// };
//
// const MainMenu = () => {
//   return (
//     <Menu
//       label="Main Menu"
//       items={[
//         { label: <MenuItem label="First Action" icon={<Gremlin size="large"/>} />, onClick: e => e, },
//         { label: <MenuItem label="Second Action" icon={<Gremlin/>} />, onClick: e => e, },
//         { label: <MenuItem label="Third Action" icon={<Gremlin/>} />, onClick: e => e, },
//         { label: <MenuItem label="Fourth Action" icon={<Gremlin/>} />, onClick: e => e, },
//         { label: <MenuItem label="Fifth Action" icon={<Gremlin/>} />, onClick: e => e, },
//       ]}
//       icon={<Gremlin/>}
//       dropProps={{ style: { zIndex: 10001 }}}
//       size="medium"
//     />
//   );
// };


const FixedBox = styled(Box)`
  position: fixed;
  width: 100%;
`;

const AppBar = (props) => {
  // const [menuOpen, setMenuOpen] = useState(false);
  // const handleClick = e => setMenuOpen(!menuOpen);
  // const size = useContext(ResponsiveContext);

  return (
    <FixedBox
      background="rgba(255,255,255,0.95)"
      direction="row"
      align="center"
      justify="between"
      pad={{horizontal: 'medium', vertical: 'xsmall'}}
      elevation='medium'
      style={{zIndex: 10000}}
      {...props}
    >
      <Anchor href="/">
        <AppBarLogo height="80px"/>
        {/*<Image src={appBarLogo} height={80} alt="Fuzing Logo"/>*/}
      </Anchor>
      <Button
        // href={`/r/${options.trial14DayCode}?ref=appbar`}
        href={'/download'}
        primary
        size="small"
        label="Totally FREE"
      />
    </FixedBox>
  );

};

export default AppBar;




