

//
// A component to render text errors
//


import React from 'react';
import { Text, Box } from "grommet";

const FormErrors = (props) => {
  return (
    props.errors ?
      <Box>
        {props.errors.map(e => <Text key={e} color="status-critical">{e}</Text>)}
      </Box>
      : null
  );

};

export default FormErrors;


