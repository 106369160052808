
// TODO- WARNING - make sure serverPrefix is '' before deploying to production
const options = {
  serverPrefix: "", // WARNING - leave as double-quoted string - we use sed to edit!
  cardMinWidth: '80%',
  appleAppId: 'relax-de-stress-and-sleep-well/id1531788528',
  googleAppId: 'com.fuzing.fuzing',
  tokenKey: 'token',
  trial14DayCode: 'PXPU946TQ',

  colors: {
    brand: "#3af9fc",           // cyan
    secondary: "#9c60e2",       // purple
    text: "#142b66",            //
    app: "#6495ed",             // cornflower color
    banner: "#c0d3fd",          // light blue banner
  },

  grommet: {
    button: {
      color: `linear-gradient(to right, #34bbfb, #228cf9)`,
    },
    box: {
      color: 'linear-gradient(#228cf9, #fff, #fff, #b08de7)',
    },
    mainBackground: {
      outerGradient: 'linear-gradient(#9f74e2,white)',
      innerGradient: 'linear-gradient(#c0d3fd,white)',
    }
  },

  // banner heights in pixels must be fixed in order not to get a
  // cumulative layout shift penalty
  bannerHeights: {
    default: 300,
    small: 225,
    medium: 300,
    large: 380,
  },
};


options.webServer = `https://${options.serverPrefix}www.fuzing.com`;
options.apiServer = `https://${options.serverPrefix}api.fuzing.com`;
options.apiPrefix = `${options.apiServer}/v1`;
options.richDataLogoUrl = `https://fuzing.s3.amazonaws.com/web/images/fuzing-logo-rich-data.png`;

if (options.serverPrefix != '') {
  function warningBanner() {
      console.log("-----------------------------------------------------------------------------------------------");
      console.log("-----------------------------------------------------------------------------------------------");
      console.log("-----------------------------------------------------------------------------------------------");
      console.log("--                W  A  R  N  I  N  G   -   T  E  S  T    M  O  D  E                          -");
      console.log("-----------------------------------------------------------------------------------------------");
      console.log("-----------------------------------------------------------------------------------------------");
      console.log("-----------------------------------------------------------------------------------------------");
  }

  warningBanner();
  setInterval(warningBanner, 30000);
}

export default options;



