import React from 'react';

import {
  Box,
  Heading,
} from "grommet";
import {Helmet} from "react-helmet-async";

function ServerErrorPage() {
  return (
    <Box>
      <Helmet>
        <title>Fuzing - Server Error</title>
        <meta name="description" content="Fuzing Server Error"/>
      </Helmet>

      <Heading>Server Error</Heading>
    </Box>
  );
}

export default ServerErrorPage;
