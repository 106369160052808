
import axios from 'axios';
import options from "../options";
const axiosInstance = axios.create({});
axiosInstance.interceptors.request.use((request) => {
  let token;
  if (typeof window !== 'undefined') token = window.localStorage.getItem(options.tokenKey);
  if (token)
    request.headers.Authorization = `Bearer ${token}`;
  return request;
});

export default axiosInstance;


