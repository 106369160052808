import React from 'react';
import {Box, Heading} from 'grommet';
// import FuzingInfoCard from "../cards/FuzingInfoCard";
import RatingCard from "../cards/RatingCard";

// import jasmineOUrl from "../../images/faces/jasmine-o.jpg";
// import angelaSUrl from "../../images/faces/angela-s.jpg";
// import billJUrl from "../../images/faces/bill-j.jpg";
// import katherineWUrl from "../../images/faces/katherine-w.jpg";

const jasmineOUrl = "/images/faces/jasmine-o.jpg";
const angelaSUrl = "/images/faces/angela-s.jpg";
const billJUrl = "/images/faces/bill-j.jpg";
const katherineWUrl = "/images/faces/katherine-w.jpg";

const ratings = [
  {
    rating: 5.0,
    raterName: "Jasmine O.",
    ratingText: "I just started using this app and it's simply awesome.  Incense, chamomile tea, Sleep Tale.... Zen!" +
      "  I've been sleeping better for the past week and I love it.  I can't believe it's totally free without" +
      " annoying ads.",
    imageUrl: jasmineOUrl,
  },
  {
    rating: 4.0,
    raterName: "Katherine W.",
    ratingText: "Not the variety of some of the other apps (hence the 4 stars), but I do much prefer the" +
      " voices used in this one, both for meditation and for the sleep" +
      " tales.  So soothing, thank you",
    imageUrl: katherineWUrl,
  },
  {
    rating: 5.0,
    raterName: "Bill J.",
    ratingText: "This is one of the most useful apps that I use pretty much every night of the week to help me get to" +
      " sleep.  My favorites are the sleep streams.  I love the fact that it's free without any ads" +
      " or gotchas within the App.",
    imageUrl: billJUrl,
  },
  {
    rating: 5.0,
    raterName: "Angela S.",
    ratingText: "I'm actually a therapist (with my own stress and sleep issues), but I use this app to help me wind" +
      " down and sleep.  I LOVE the accents used for the Sleep Tales. I now recommend this to all my patients with" +
      " sleep problems.",
    imageUrl: angelaSUrl,
  },

];


export default function FuzingRatingCards({children}) {
  return (
    <Box>
      <Box width={{ min: "small"}} margin="medium" align="center">
        <Box align="center">
          <Box direction="row" wrap={true} justify="center">
            {ratings.map((r, index) => <RatingCard key={index} rating={r.rating} raterName={r.raterName} ratingText={r.ratingText} imageUrl={r.imageUrl}/>)}
          </Box>

        </Box>
      </Box>

      <Box align="center">
        {children}
      </Box>

    </Box>

  );
}
