

import React from 'react';
import {
  Box,
  Heading,
  Text,
} from "grommet";
import {Helmet} from "react-helmet-async";
import {useHTTP} from "../custom-hooks/useHTTP";

function NotFoundPage() {
  const httpContext = useHTTP();
  httpContext.statusCode = 404;

  return (
    <Box>
      <Helmet>
        <title>Fuzing - Page Not Found</title>
        <meta name="description" content="Fuzing Page Not Found"/>
      </Helmet>

      <Box margin={{ top: '100px'}}>
        <Heading>Page Not Found</Heading>
        <Text>Oops! We can't seem to find what you're looking for!</Text>
      </Box>
    </Box>
  );
}

export default NotFoundPage;
