import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import './frontend/index.css';
// import App from './App';
import App from './frontend/App';
import reportWebVitals from './reportWebVitals';

import TagManager from 'react-gtm-module';


import {BrowserRouter as Router} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import { createBrowserSSEContext } from "./frontend/custom-hooks/useSSE";
import { createBrowserHTTPContext } from "./frontend/custom-hooks/useHTTP";
//
// auth,preview are obtained from google tag manager  Admin -> Environments -> Get Snippet
// Production:
//    aNDRhxgR8u5VIUz-mN3lKQ, env-1, https://www.fuzing.com
// Test:
//    E83fcaeilbM9Jlbhd-4pgg, env-5, https://testwww.fuzing.com
// Staging:
//    5uG8f8oUQf0aIfBF3yx8kA, env-6, https://stagingwww.fuzing.com
//
//

// For turorial on setup see: https://www.samuelschmitt.com/google-tag-manager-analytics-tutorial
const tagManagerArgs = {
  gtmId: 'GTM-N7ZHPK9',         // from the google tag manager
  auth: 'aNDRhxgR8u5VIUz-mN3lKQ',
  preview: 'env-1',
};

TagManager.initialize(tagManagerArgs);

const BrowserDataContext = createBrowserSSEContext();
const BrowserHTTPContext = createBrowserHTTPContext();

// ReactDOM.render(
ReactDOM.hydrate(
  <StrictMode>
    <BrowserHTTPContext>
      <BrowserDataContext>
        <HelmetProvider>
          <Router>
            <App />
          </Router>
        </HelmetProvider>
      </BrowserDataContext>
    </BrowserHTTPContext>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
