
import React from 'react';
import options from "../../options";

import {
  Box,
} from "grommet";

import MarkdownFromUrl from "../../components/miscellaneous/MarkdownFromUrl";
import {Helmet} from "react-helmet-async";

function TermsOfService() {
  return (
    <Box align="center">
      <Helmet>
        <title>Fuzing - Privacy Notice</title>
        <meta name="description" content="Privacy notice for Fuzing"/>
      </Helmet>

      <Box margin={{ top: "100px", horizontal: "large" }}>
        <MarkdownFromUrl
          url={`${options.apiPrefix}/legal/privacy-notice/en`}
        />
      </Box>
    </Box>
  );
}

export default TermsOfService;
