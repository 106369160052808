
import React, {useContext} from 'react';
import options from "../../options";

import { useSSE } from "../../custom-hooks/useSSE";
import NotFoundPage from "../NotFoundPage";

import {useParams} from "react-router";

import {
  Box,
  Image,
  ResponsiveContext,
} from "grommet";
import {Helmet} from "react-helmet-async";
import axiosInstance from "../../axios";

import { BlogHeading, BlogAboutUs, BlogMiniPosts, BlogMidiPosts, BlogSell } from "../../components/blog";




function BlogCategory({ category, size }) {
  const bannerHeight = options.bannerHeights[size] || options.bannerHeights.default;

  return (
    <Box>
      <Helmet>
        <title>{category.htmlTitle}</title>
        <meta name="description" content={category.htmlDescription}/>
      </Helmet>
      <Box margin={{top: "100px", horizontal: "large"}}>
        <Image height={bannerHeight} src={category.bannerImageUrl} alt={category.bannerImageAltText}/>
        <Box direction="row-responsive">
          <Box fill basis="2/3" pad="medium">
            <BlogHeading level={1} margin={{ bottom: 'small'}}>{category.name}</BlogHeading>
            <Box fill direction="row-responsive" align="center">
              <BlogMidiPosts posts={category.recentPosts} size={size}/>
            </Box>
          </Box>
          <Box fill basis="1/3" pad="medium">
            <BlogAboutUs/>
            <BlogMiniPosts heading="Popular Posts" posts={category.popularPosts} size={size}/>
          </Box>
        </Box>

      </Box>
      <BlogSell/>
    </Box>
  );

}



function BlogCategoryPage() {
  const params = useParams();
  const size = useContext(ResponsiveContext);

  console.log(`GET ${options.apiPrefix}/cms/blog-category/${params.slug}/${params.page || '1'}`);

  const [blogCategory, error] = useSSE(async () => {
    try {
      const {data: {blogCategory}} = await axiosInstance.get(`${options.apiPrefix}/cms/blog-category/${params.slug}/${params.page || '1'}`);
      return blogCategory;
    }
    catch (e) {
      console.log("Error is ", e);
      return { error: "Not Found" };
    }
  }, []);
  console.log("blog category data is", blogCategory);

  if (!blogCategory) return null;
  if (blogCategory.error) return <NotFoundPage/>
  return <BlogCategory category={blogCategory} size={size}/>;
}

export default BlogCategoryPage;
