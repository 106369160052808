

import React, { useState } from 'react';
import {Form as GrommetForm, Button} from "grommet";
import { FormRefresh, Send } from "grommet-icons";
import Spin from "../../animations/Spin";
import ParentWidth from "../../miscellaneous/ParentWidth/ParentWidth";

import PageCard from "../../cards/PageCard";

import {Box, TextInput, TextArea} from "grommet";
import {Formik} from "formik";
import {FormikFormFastField} from "../../controls/Formik";
import Schema from "../../../../shared/Schema/Schema";

import FormErrors from "../../controls/FormErrors/FormErrors";

import {useNavigate} from "react-router";

import axiosInstance from "../../../axios";
import options from "../../../options";
import FormSubmitButtonIcon from "../../icons/FormSubmitButtonIcon";

const schema = new Schema({
  // email: {
  //   validator: (value, values, options) =>
  //     /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ? null :
  //       "Please enter a valid email",
  // },
  email: {
    validator: (value, values, options) => {
      const v = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
      return v ? null : "Please enter a valid email";
    }
  },
}, {
  // errorGenerator: error => <ParentWidth>{error}</ParentWidth>,
  stripUnknown: true,
  // custom options
  // language: "fr",
});


const initialValues = {
  email: '',
};

const ForgotPasswordForm = (props) => {
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState(null);

  const handleSubmit = async (values, {setSubmitting, resetForm} ) => {

    // console.log("--------------------------- Handle Submit for forgot password ------------------------------");
    // console.log("values is", values);
    // console.log("Validate returns", await schema.validate(values));

    const castValues = schema.cast(values);
    // console.log(values, "becomes", castValues);

    //
    //
    // await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      const {data: {error}} = await axiosInstance.post(`${options.apiPrefix}/auth/forgot-password`, {
        // data: castValues
        ...castValues
      });
      if (error) {
        setFormErrors([error.message]);
      } else {
        // navigate('/');
        setFormErrors(['We have sent an email that contains instructions on how to reset your password']);
      }
    }
    catch (e) {
      setFormErrors(["Something went wrong"]);
      navigate('/forgot-password');
    }

    setSubmitting(false);

    // resetForm(values);
  };

  return (
    <PageCard
      heading="Forgot Password"
      {...props}
    >
      {
        (props) => <Formik
          initialValues={initialValues}
          validate={values => schema.validate(values)}
          // validate={() => undefined}
          // submitForm={handleSubmit}
          onSubmit={handleSubmit}
        >
          {
            (props) => {

              const {dirty, isSubmitting, isValid, handleSubmit} = props;

              function resetFormErrorsInterceptor(e) { setFormErrors(null); return e; }

              return (
                <GrommetForm onSubmit={handleSubmit}>
                  {/*<Box flex direction="column" width="large">*/}
                  <Box flex direction="column" margin="small">
                    <FormikFormFastField
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="someone@somewhere.com  "
                      // help={<ParentWidth>Please type your first name and do a good job with it - so that we can fix things and all</ParentWidth>}
                      // help="Please type your first name and do a good job with it - so that we can fix things and all"
                      // info="Some information"
                      onChangeInterceptor={resetFormErrorsInterceptor}
                      maxLength={60}
                      size="large"
                      component={TextInput}
                    />

                    <Box>
                      <FormErrors errors={formErrors} />
                    </Box>

                    <Box alignSelf="center" flex={{ grow: 1 }}>
                      <Button
                        primary
                        type="submit"
                        focusIndicator={false}
                        disabled={!dirty || isSubmitting || !isValid}
                        label="Submit"
                        icon={<FormSubmitButtonIcon/>}
                      />
                    </Box>
                  </Box>

                </GrommetForm>
              );
            }
          }
        </Formik>
      }

    </PageCard>
  );
};

export default ForgotPasswordForm;

