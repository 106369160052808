import React, {Suspense, lazy} from 'react';

import {
  BrowserRouter as Router,
  //Switch,
  Route,
  Routes,
  // Link
} from "react-router-dom";
import Loadable from 'react-loadable';

import HomePage from "../pages/HomePage";
import ServerErrorPage from "../pages/ServerErrorPage";
import ContactUsPage from "../pages/ContactUsPage";
import CookiePolicyPage from "../pages/legal/CookiePolicyPage";
import DownloadPage from "../pages/partners/users/DownloadPage";
import PrivacyNotice from "../pages/legal/PrivacyNoticePage";
import AppleAppEulaPage from "../pages/legal/AppleAppEulaPage";
import TermsOfUsePage from "../pages/legal/TermsOfUsePage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
// import RedeemPage from "../pages/partners/users/RedeemPage";
// import ReferralLandingPage from "../pages/partners/users/ReferralLandingPage";
// import RemovePromotionPage from "../pages/partners/users/RemovePromotionPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
// import SigninPage from "../pages/auth/SigninPage";
// import TestPage from "../pages/test/TestPage";
import UnsubscribePage from "../pages/auth/UnsubscribePage";
import NotFoundPage from "../pages/NotFoundPage";
import BlogRootPage from "../pages/cms/BlogRootPage";

// import BusyLayer from "../components/BusyOverlay/BusyLayer";

// lazily loaded sub-routes
// const PartnersRoutes = lazy(() => import(/* webpackChunkName: "partners-routes" */ "./PartnersRoutes"));
// import PartnersRoutes from "./PartnersRoutes";
// const StaffRoutes = lazy(() => import(/* webpackChunkName: "staff-routes" */ "./StaffRoutes"));
// import StaffRoutes from "./StaffRoutes";
// const CMSLandingRoutes = lazy(() => import(/* webpackChunkName: "cms-routes" */ "./CMSLandingRoutes"));
import CMSLandingRoutes from "./CMSLandingRoutes";
import CMSBlogPostRoutes from "./CMSBlogPostRoutes";
import CMSBlogCategoryRoutes from "./CMSBlogCategoryRoutes";

// const CMSBlogPostRoutes = Loadable({
//   loader: () => import("./CMSBlogPostRoutes"),
//   loading: () => <div>...loading</div>,
// })

export default function RootRoutes(props) {
  return (
    // <Suspense fallback={<BusyLayer/>}>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/server-error" element={<ServerErrorPage/>}/>
        <Route path="/blog" element={<BlogRootPage/>}/>
        <Route path="/blog/category/*" element={<CMSBlogCategoryRoutes/>}/>
        <Route path="/blog/*" element={<CMSBlogPostRoutes/>}/>
        <Route path="/l/*" element={<CMSLandingRoutes/>}/>
        <Route path="/contact-us" element={<ContactUsPage/>}/>
        <Route path="/cookie-policy" element={<CookiePolicyPage/>}/>
        <Route path="/download" element={<DownloadPage/>}/>
        <Route path="/privacy-notice" element={<PrivacyNotice/>}/>
        <Route path="/apple-app-eula" element={<AppleAppEulaPage/>}/>
        <Route path="/terms-of-use" element={<TermsOfUsePage/>}/>
        <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
        {/*<Route path="/r/:referralCode" element={<ReferralLandingPage/>}/>*/}
        {/*<Route path="/redeem" element={<RedeemPage/>}/>*/}
        {/*<Route path="/remove-promotion" element={<RemovePromotionPage/>}/>*/}
        {/*<Route path="/partners/*" element={<PartnersRoutes/>}/>*/}
        {/*<Route path="/staff/*" element={<StaffRoutes/>}/>*/}
        <Route path="/reset-password" element={<ResetPasswordPage/>}/>
        {/*<Route path="/signin" element={<SigninPage/>}/>*/}
        {/*<Route path="/test" element={<TestPage/>}/>*/}
        <Route path="/unsubscribe" element={<UnsubscribePage/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    // </Suspense>
  );
}

