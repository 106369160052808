
import React from 'react';
import styled from "styled-components";
import {Paragraph} from "grommet";

const BlogParagraph = styled(Paragraph)`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
`;
export default BlogParagraph;

