
import React, { useState } from 'react';
import { Box } from "grommet";

import BusyLayer from "./BusyLayer";

export default function BusyOverlay({children, ...rest}) {
  const [busyOverlay, setBusyOverlay] = useState(false);

  return (
    <Box>
      <Box>
        {
          children({ ...rest, setBusyOverlay })
        }
      </Box>
      {busyOverlay && <BusyLayer/>}
    </Box>
  );
}
