
import React, {useEffect, useState, useContext} from 'react';
import styled from 'styled-components';
import options from "../../options";

import { useSSE } from "../../custom-hooks/useSSE";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";


import {useLocation} from "react-router";

import NotFoundPage from "../NotFoundPage";

import {useParams} from "react-router";

// grommet markdown uses - https://github.com/probablyup/markdown-to-jsx
import {
  Markdown,
  Box,
  Image,
  Avatar,
  ResponsiveContext,
  Text,
  Anchor,
} from "grommet";
import {Helmet} from "react-helmet-async";
import axiosInstance from "../../axios";
// import moment from "moment-timezone";

import { BlogAboutUs, BlogHeading, BlogParagraph, BlogMiniPosts, BlogSell, BlogAnchor } from "../../components/blog";

import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, PinterestShareButton, PinterestIcon } from "react-share";

// import { ReactComponent as ShareFacebookSvg } from "../../images/svgs/share-facebook.svg";
// import shareFacebookSvg from "../../images/svgs/share-facebook.svg";
// import shareTwitterSvg from "../../images/svgs/share-twitter.svg";
// import shareLinkSvg from "../../images/svgs/share-share.svg";



// const StyledReactMarkdown = styled(ReactMarkdown)`
//   //p {
//   //  font-size: 30px;
//   //  line-height: 32px;
//   //}
//   //li {
//   //  font-size: 30px;
//   //  line-height: 32px;
//   //}
//   h1 {
//     font-size: 3em;
//     font-weight: lighter;
//     color: lightblue;
//   }
//   h2 {
//     font-size: 2em;
//     font-weight: lighter !important;
//   }
//   img {
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
//     width: 55%;
//   }
//   ol {
//     margin: 0 0 1.5em;
//     padding: 0;
//     counter-reset: item;
//     list-style: none;
//   }
//   ol > li {
//     margin: 0;
//     //padding: 0 0 0 2em;
//     //text-indent: -2em;
//     list-style-type: none;
//     counter-increment: item;
//
//     &::before {
//       display: inline-block;
//       width: 1em;
//       //color: red;
//       padding-right: 0.5em;
//       font-weight: bold;
//       text-align: right;
//       content: counter(item) ".";
//     }
//
//     & p {
//       display: inline-block;
//       //padding: 0 0 0 2em;
//     }
//   }
// `;


const StyledMarkdown = styled(Markdown)`
  h1 {
    font-size: 2em;
  }

  h2 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-size: 1.6em;
  }

  h3 {
    margin-top: 2.5em;
    margin-bottom: 0.5em;
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 0.75em;
    margin-top: 2.5em;
    max-width: 100%;
    line-height: 1.2em;
  }
  
  li {
    line-height: 1.8em;
    font-size: 1.1em;
  }

  p {
    margin-top: 0.85em;
    margin-bottom: 1.6em;
    width: 100%;
    max-width: 100%;
    font-size: 1.1em;
    line-height: 2.0em;
  }

  img {
    display: block;
    margin: 3em auto;
    width: 75%;
  }

  .youtube-container {
    position: relative;
    padding-bottom: 56.25%;
    //padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  a {
    &:hover {
      text-decoration: none;
      color: gray;
    }

    text-decoration: none;
    font-weight: 600;
    color: #30436d;
  }

  //ol {
  //  margin: 0 0 1.5em;
  //  padding: 0;
  //  counter-reset: item;
  //  list-style: none;
  //}
  //ol > li {
  //  margin: 0;
  //  padding: 0 0 0 2em;
  //  text-indent: -2em;
  //  list-style-type: none;
  //  counter-increment: item;
  //}
  //ol > li:before {
  //  display: inline-block;
  //  width: 1em;
  //  color: red;
  //  padding-right: 0.5em;
  //  font-weight: bold;
  //  text-align: right;
  //  content: counter(item) ". ";
  //}
`;


// const SomeImage = styled.img`
//   width: 75%;
// `;



// function MiniPost({post}) {
//   // const postDate = moment(post.postDate);
//
//   return (
//     <Anchor margin={{ bottom: "medium" }} href={`/blog/${post.slug}`} style={{ textDecoration: 'none'}}>
//       <Box fill direction="row" align="center">
//         <Image width="100px" src={post.image3to2Url} margin={{ right: 'medium'}} alt={post.image3to2AltText}/>
//         <Box>
//           <Text>{post.title}</Text>
//           {/*<Text>{postDate.format('MMM DD')}</Text>*/}
//           <Text>{new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(Date.parse(post.postDate))}</Text>
//         </Box>
//       </Box>
//     </Anchor>
//   );
// }

function AboutTheAuthor({author}) {
  return (
    <Box fill>
      <BlogHeading level={4} margin={{bottom : "0.25em"}}>About The Author</BlogHeading>
      <BlogParagraph margin={{ top: "0.25em" }}>{author.biography}</BlogParagraph>
    </Box>
  );
}

function ShareButtons({ post }) {
  const size = 48;
  const round = true;
  const url = `https://www.fuzing.com/blog/${post.slug}`;

  return (
    <Box direction="row" align="center" margin={{vertical: "medium"}}>
      <Text weight="bold" size="large" margin={{right: "small"}}>Share This</Text>
      <Box margin={{right: "small"}}>
        <FacebookShareButton url={url} quote={post.title} hashtag={post.hashTags.split(",")}>
          <FacebookIcon size={size} round={round}/>
        </FacebookShareButton>
      </Box>
      <Box margin={{right: "small"}}>
        <TwitterShareButton url={url} title={post.title} hashtags={post.hashTags.split(",").map(tag => tag.substr(1))}>
          <TwitterIcon size={size} round={round}/>
        </TwitterShareButton>
      </Box>
      <Box margin={{right: "small"}}>
        <PinterestShareButton url={url} media={`https://www.fuzing.com${post.pinterestImageUrl || post.ogImageUrl}`} description={post.title}>
          <PinterestIcon size={size} round={round}/>
        </PinterestShareButton>
      </Box>
      <Box margin={{right: "small"}}>
        <EmailShareButton url={url} subject={`Fuzing Post: ${post.title}`} body={`Check out the post on ${post.title}, ${post.subTitle}`}>
          <EmailIcon size={size} round={round}/>
        </EmailShareButton>
      </Box>
    </Box>
  );
}

function NextPrevBox({label, post}) {
  return post &&
  <BlogAnchor href={`/blog/${post.slug}`}>
    <Box fill pad="medium" align="center" border={{
      color: "lightgray",
      size: "small",
      side: "all",
      style: "solid",
    }}>
      <Text size="small">{label}</Text>
      <Text>{post.title}</Text>
    </Box>
  </BlogAnchor>;
}

function ATag({ children, href, ...rest}) {
  if (/fuzing/i.test(href))
    return <a href={href} {...rest}>{children}</a>;

  // external link
  return <a href={href} target="_blank" rel="noreferrer" {...rest}>{children}</a>;

  // component: ({children, href, ...rest}) => <a href={href} target={/fuzing/i.test(href) ? "_self" : "_blank"} {...rest}>{children}</a>,
}

function BlogPost({ post, size }) {
  // const postDate = moment(post.postDate);
  const thisLocation = useLocation();
  const thisUrl = `${options.webServer}${thisLocation.pathname}`;
  const thisImageUrl = `${options.webServer}${post.image3to2Url}`;
  const logoUrl = options.richDataLogoUrl;
  const bannerHeight = options.bannerHeights[size] || options.bannerHeights.default;

  console.log("Location is", thisLocation);
  console.log("Size is", size);


  return (
    <Box>
      <Helmet>
        <title>{post.htmlTitle}</title>
        <meta name="description" content={post.htmlDescription}/>
        <meta property="og:image" content={`${options.webServer}/${post.ogImageUrl}`}/>
        <meta property="og:image:alt" content={post.ogImageAltText}/>
        <meta property="og:title" content={post.title}/>
        <meta property="og:type" content="article"/>
        <meta property="article:author" content={post.author.name}/>
        <meta property="article:publisher" content="https://www.fuzing.com"/>
        <link rel="canonical" href={thisUrl}/>
        <link rel="alternate" href={thisUrl} hreflang="en"/>
        <script type="application/ld+json">{`{
            "@context": "https://schema.org/",
            "@type": "BlogPosting",
            "url": "${thisUrl}",
            "image": ["${thisImageUrl}"],
            "headline": "${post.title}",
            "datePublished": "${post.createdAt}",
            "dateModified": "${post.updatedAt}",
            "description": "${post.htmlDescription}",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${thisUrl}"
            },
            "author": {
              "@type": "Person",
              "name": "${post.author.name}"
            },
            "editor": {
              "@type": "Person",
              "name": "Fuzing"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Fuzing LLC",
              "logo": {
                "@type": "ImageObject",
                "url": "${logoUrl}"
              }
            },
            "license": "Fuzing LLC 2021"
        }`}
        </script>
      </Helmet>

      <Box margin={{top: "100px", horizontal: "large"}}>
        <Image height={bannerHeight} src={post.bannerImageUrl} alt={post.bannerImageAltText}/>
        <Box direction="row-responsive">
          <Box fill basis="2/3" pad="medium">
            <BlogHeading level={1} margin={{ bottom: '0', top: "small"}}>{post.title}</BlogHeading>
            <BlogHeading level={2} margin={{ top: 'medium' }}>{post.subTitle}</BlogHeading>
            <Box direction="row-responsive" align="center">
              <Box fill basis="1/2" direction="row">
                <Avatar size="large" src={post.author.image} alt={post.author.name}/>
                <Box justify="center" margin={{ left: "small" }}>
                  <Box direction="row">
                    <Text color="#222">By:</Text>
                    <Text>{post.author.name}</Text>
                  </Box>
                  <Box direction="row">
                    <Text color="#222">Posted:</Text>
                    {/*<Text>{postDate.format('MMM DD')}</Text>*/}
                    <Text>{new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(Date.parse(post.postDate))}</Text>
                  </Box>
                  <Text color="#222">{Math.ceil(post.post.length / 750)} min read</Text>
                </Box>
              </Box>

              <Box fill basis="1/2" margin={{ vertical: "medium" }}>
                <ShareButtons post={post}/>
              </Box>
            </Box>

            {/*<StyledReactMarkdown*/}
            {/*  source={post.post || ''}*/}
            {/*  plugins={gfm}*/}
            {/*  // renderers={{*/}
            {/*  //   image: SomeImage*/}
            {/*  // }}*/}
            {/*/>*/}

            <StyledMarkdown
              options={{
                overrides: {
                  a: {
                    component: ATag,
                    // props: {
                    //   target: '_blank',
                    // }
                  }
                }
              }}
            >
              {post.post || ''}
            </StyledMarkdown>

            <ShareButtons post={post}/>
            <AboutTheAuthor author={post.author}/>

            <Box direction="row-responsive">
              <Box fill basis="1/2" margin="small">
                <NextPrevBox label="Previous" post={post.previousPost}/>
              </Box>
              <Box fill basis="1/2" margin="small">
                <NextPrevBox label="Next" post={post.nextPost}/>
              </Box>
            </Box>
          </Box>
          <Box fill basis="1/3" pad="medium">
            <BlogAboutUs/>
            <BlogMiniPosts heading="Recent Posts" posts={post.recentPosts}/>
            {/*<BlogMiniPosts heading="Popular Posts" posts={post.popularPosts}/>*/}
          </Box>
        </Box>
      </Box>
      <BlogSell sellText={post.sellText}/>
    </Box>
  );

}



function BlogPostPage() {
  const params = useParams();
  const size = useContext(ResponsiveContext);

  const [blogPost, error] = useSSE(async () => {
    try {
      const {data: {blogPost}} = await axiosInstance.get(`${options.apiPrefix}/cms/blog-post/${params.slug}`);
      return blogPost;
    }
    catch (e) {
      console.log("Error is ", e);
      return { error: "Not Found" };
    }
  }, []);
  // console.log("blog post data is", blogPost);

  if (!blogPost) return null;
  if (blogPost.error) return <NotFoundPage/>
  return <BlogPost post={blogPost} size={size}/>;
}

export default BlogPostPage;
