import React from "react";
import {Box} from "grommet";
import {BlogHeading, CategoryMini} from "./index";

export default function CategoryMinis({heading, categories, size}) {
  return (
    <Box fill>
      {heading && <BlogHeading level={3}>{heading}</BlogHeading>}
      {categories.map(category => <CategoryMini key={category.id} category={category} size={size}/>)}
    </Box>
  );
}

