

//
// PageCard is used as a page card border for pages such as contact-us, reset-password etc.
//
import React from "react";
import {Box, Heading, Card, CardHeader, CardBody } from "grommet";
import options from "../../options";

const PageCard = ({children, heading, ...rest}) => {
  return (
    <Box
      width="100%"
      align="center"
      background="linear-gradient(#c0d3fd,#f7faff)"
      pad="large"
    >
      <Card
        {...rest}
        width={{ min: options.cardMinWidth }}
        background="white"
        pad="medium"
        round="medium"
        margin={{ top: "60px" }}
      >
        {heading ? <CardHeader>
          <Heading level={1} size="large" margin="small">{heading}</Heading>
        </CardHeader> : null}
        <CardBody>
          {children({...rest})}
        </CardBody>
      </Card>
    </Box>
  );
}

export default PageCard;

