
//
// this class represents fake SyntheticEvent objects
// because some libraries (notably Formik in our case) requires synthetic events
//
const TrueFunction = () => true;
const FalseFunction = () => false;
const EmptyObject = Object.freeze({});

export default class FakeSyntheticEvent {
  constructor(target) {
    this.target = target;
  }

  preventDefault = FalseFunction;
  defaultPrevented = false;
  stoPropagation = FalseFunction;
  bubbles = false;
  cancelable = false;
  currentTarget = null;
  dispathchConfig = EmptyObject;
  targetInst = {};
  eventPhase = 3;
  isDefaultPrevented = FalseFunction;
  isPersistent = TrueFunction;
  isTrusted = true;
  nativeEvent = EmptyObject;
  timestamp = 0;
  type = '';
  _dispatchInstances = null;
  _dispatchEvents = null;
  persist = TrueFunction;
};

