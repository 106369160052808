import React from "react";
import {Box} from "grommet";
import {BlogHeading, BlogMidiPost} from "./index";

export default function BlogMidiPosts({heading, posts}) {
  return (
    <Box fill direction="row-responsive">
      {heading && <BlogHeading level={3}>{heading}</BlogHeading>}
      {posts.map(post =>
        <Box key={post.id} basis="1/3" margin="small">
          <BlogMidiPost post={post}/>
        </Box>
        )}
    </Box>
  );
}

