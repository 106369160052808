
import React from 'react';
import {Box, Image, Text, Heading } from "grommet";
import {BlogAnchor} from "./index";

export default function BlogMidiPost({post}) {
  return (
      <BlogAnchor margin={{ bottom: "medium" }} href={`/blog/${post.slug}`}>
        <Box fill align="center">
          <Image width="100%" src={post.image3to2Url} alt={post.image3to2AltText}/>
          <Box>
            <Heading level={5} margin={{ bottom: "small" }}>{post.title}</Heading>
            <Text>{post.subTitle}</Text>
            {/*<Text>{postDate.format('MMM DD')}</Text>*/}
            <Text>{new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(Date.parse(post.postDate))}</Text>
          </Box>
        </Box>
      </BlogAnchor>
  );
}
