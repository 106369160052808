
// import _ from "lodash";
// import { merge as lodashMerge, set as lodashSet, get as lodashGet } from "lodash";
import lodashMerge from "lodash/merge";
import lodashGet from "lodash/get";
import lodashSet from "lodash/set";

// import async from "async";
// import asyncReduce from 'async/reduceRight';
import asyncMap from 'async/map';

const defaultOptions = {
  errorGenerator: e => e,
};

class Schema {
  constructor(schema, options = {}) {
    this.schema = schema;
    this.options = lodashMerge({}, defaultOptions, options);
  }

  concat(otherSchema) {
    return new Schema({ ...this.schema, ...otherSchema.getSchema() }, this.options);
  }

  getSchema() {
    return this.schema;
  }

  static isSchemaNode(n) {
    return typeof n === 'object' && (n.caster || n.validator);
  }

  static isSchema(o) {
    return typeof o === 'object' && typeof o.getSchema === 'function' && typeof o.validate === 'function' && typeof o.cast === 'function';
  }


  static async _validate(schema, values, options) {
    async function asyncReduce(iter, acc, fn) {
      // console.log("asyncReduce start");
      let x = acc;
      for (let i = 0; i < iter.length; ++i) {
        x = await fn(x, iter[i]);
      }
      // await async.forEachLimit(iter, 1, async (val) => {
      //   x = await fn(acc, val);
      // });
      // console.log("asyncReduce end");
      return x;
    }


    const retValue = await asyncReduce(Object.entries(schema), {}, async (acc, [key, schemaNode]) => {
      // const fieldValue = values[key];
      const fieldValue = lodashGet(values, key);

      if (Array.isArray(schemaNode)) {
        // console.log("_validate - isArray");

        // fieldValue should also be an array (or null)
        let errored = false;
        // const errors = await async.map(fieldValue, async f => {
        const errors = await asyncMap(fieldValue, async f => {
          const r = await schemaNode[0].validate(f, options);
          if (r) errored = true;
          return r;
        });
        if (errored) lodashSet(acc, key, errors);
      } else if (Schema.isSchema(schemaNode)) {
        // console.log("_validate - isSchema");

        const errors = await schemaNode.validate(fieldValue, options);
        if (errors) lodashSet(acc, key, errors);
      } else if (Schema.isSchemaNode(schemaNode)) {
        // console.log("_validate - isSchemaNode");

        const validator = schemaNode.validator;
        if (validator) {
          let error = validator(fieldValue, values, options);
          if (error) {
            if (error.then && typeof error.then === 'function') {
              error = await error;
            }
            if (error) {
              // console.log("Error is ", error);
              lodashSet(acc, key, options.errorGenerator(error));
            }
          }
        }
      } else if (schemaNode) {
        // console.log("_validate - schemaNode");

        const r = await Schema._validate(schemaNode, fieldValue, options);
        if (Object.keys(r).length > 0)
          lodashSet(acc, key, r);
      } else {
        console.log("_validate Yikes!");
      }

      // console.log("Returning new acc", acc);
      return acc;
    });


    return retValue;
  }

  async validate(values, options) {
    try {
      const opts = options ? lodashMerge(this.options, options) : this.options;
      const r = await Schema._validate(this.schema, values, opts);
      return Object.keys(r).length > 0 ? r : undefined;
    }
    catch (e) {
      console.log("Schema validate exception", e);
      return undefined;
    }
  }

  static _cast(schema, values, options) {
    let r = Object.entries(schema).reduce((acc, [key, schemaNode]) => {
      // console.log("cast field value for ", key, "is", lodashGet(values, key));
      const fieldValue = lodashGet(values, key);

      if (Array.isArray(schemaNode)) {
        // fieldValue should also be an array (or null)
        lodashSet(acc, key, fieldValue.map(f => schemaNode[0].cast(f, options)));
      }
      else if (Schema.isSchema(schemaNode)) {
        lodashSet(acc, key, schemaNode.cast(fieldValue, options));
      }
      else if (Schema.isSchemaNode(schemaNode)) {
        const caster = schemaNode.caster || (e => e);
        lodashSet(acc, key, caster(fieldValue, values, options));
      }
      else if (schemaNode)
        lodashSet(acc, key, Schema._cast(schemaNode, fieldValue, options));
      else {
        console.log("_cast Yikes");
      }
      return acc;
    }, {});

    if (!options.stripUnknown) r = lodashMerge({}, values, r);

    return r;
  }
  cast(values, options = {}) {
    return Schema._cast(this.schema, values, lodashMerge(this.options, options));
  }
}

export default Schema;
