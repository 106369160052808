


import React from 'react';

export default function({appId, width, height}) {
  return (
    <a
      href={`https://apps.apple.com/us/app/${appId}`}
    >
      <img
        width={width}
        height={height}
        alt='Get it on the Apple App Store'
        src='/apple-app-store.svg'
      />
    </a>
  );
}

