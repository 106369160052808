
import React, {useContext} from 'react';
import options from "../../options";

import { useSSE } from "../../custom-hooks/useSSE";
import NotFoundPage from "../NotFoundPage";

import {useParams} from "react-router";

import {
  Box,
  Image,
  Heading,
  Paragraph,
  ResponsiveContext,
} from "grommet";
import {Helmet} from "react-helmet-async";
import axiosInstance from "../../axios";

import { BlogHeading, BlogMiniPosts, BlogAboutUs, BlogSell, CategoryMinis } from "../../components/blog";


function BlogRoot({ root, size }) {
  return (
    <Box>
      <Helmet>
        <title>Fuzing Blog</title>
        <meta name="description" content="The fuzing blog"/>
      </Helmet>
      <Box margin={{top: "100px", horizontal: "large"}}>
        {/*<Image src={root.bannerImageUrl} alt={root.bannerImageAltText}/>*/}
        <Box direction="row-responsive" align="center">
          <Box fill basis="2/3" pad="small">
            <Heading level={1} textAlign="center">Fuzing Blog</Heading>
            <Paragraph textAlign="center">
              Welcome to the Fuzing Blog
            </Paragraph>
            <CategoryMinis categories={root.categories} size={size}/>
          </Box>
          <Box fill basis="1/3" pad="medium">
            <BlogAboutUs/>
            <BlogMiniPosts heading="Popular Posts" posts={root.popularPosts}/>
          </Box>
        </Box>

      </Box>
      <BlogSell/>
    </Box>
  );

}



function BlogRootPage() {
  const params = useParams();
  const size = useContext(ResponsiveContext);



  console.log(`GET ${options.apiPrefix}/cms/blog-root`);
  console.log("Size", size);

  const [blogRoot, error] = useSSE(async () => {
    try {
      const {data: {blogRoot}} = await axiosInstance.get(`${options.apiPrefix}/cms/blog-root`);
      return blogRoot;
    }
    catch (e) {
      console.log("Error is ", e);
      return { error: "Not Found" };
    }
  }, []);
  console.log("blog root data is", blogRoot);

  if (!blogRoot) return null;
  if (blogRoot.error) return <NotFoundPage/>
  return <BlogRoot root={blogRoot} size={size}/>;
}

export default BlogRootPage;
