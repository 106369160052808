import React from 'react';
import {Box, Text, Paragraph, Image} from "grommet";
import {BlogHeading, BlogParagraph} from "./index";

import AppStoreBadge from "../app-store-badges/AppStoreBadge";
import PlayStoreBadge from "../app-store-badges/PlayStoreBadge";
import options from "../../options";
// import LazyLoad from 'react-lazyload';

export default function BlogSell({ sellText }) {
  return (
    <Box  align="center" background="black" margin="small" pad="large">
      <BlogHeading level={2} margin={{ bottom: "small" }} color="white" textAlign="center">
        The Fuzing App
      </BlogHeading>
      {sellText && <BlogHeading level={3} color="white" textAlign="center">{sellText}</BlogHeading>}
      <Box fill direction="row-responsive" align="center">
        <Box fill basis="1/2" align="center">
          <Paragraph color="white" size="large" textAlign="center" style={{ textTransform: 'capitalize' }}>
            Totally FREE, with no ads and no in-app purchases or subscriptions
          </Paragraph>

          <Box>
            <AppStoreBadge
              width='200'
              height='100'
              appId={options.appleAppId}
            />
          </Box>
          <Box>
            <PlayStoreBadge
              width={350*2/3}
              height={150*2/3}
              appId={options.googleAppId}
            />
          </Box>

        </Box>
        <Box fill basis="1/2" align="center">
          {/*<LazyLoad once offset={100} height={400}>*/}
            <Image width="75%" src="/images/blog/blog-bottom.jpg" alt="Fuzing App Screenshots"/>
          {/*</LazyLoad>*/}
        </Box>
      </Box>
    </Box>
  );
}
