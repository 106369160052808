
import React from "react";
import FuzingBrandContent from "../components/FuzingBrandContent";
import {Helmet} from "react-helmet-async";
import {Box} from "grommet";

export default function HomePage(props) {
  return (
    <Box>
      <Helmet>
        <title>The Fuzing App - Relax, De-Stress and Sleep Well</title>
        <meta
          name="description"
          content="The Fuzing App will help you to Relax, De-Stress and Sleep Well.  Our App provides adult sleep tales, award winning meditations, relaxing melodies, nature sounds and music - totally Free with no ads or in-app purchases"
        />
      </Helmet>
      <FuzingBrandContent
        displayTrialButton={true}
        displayFuzingInfo={true}
        promotionDetails={null}
      />
    </Box>
  );

}




