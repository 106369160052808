


import React, { useEffect, useState } from 'react';
import axiosInstance from "../../../axios";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {Markdown} from "grommet";

import { useSSE } from "../../../custom-hooks/useSSE";

function MarkdownFromUrl({ url }) {
  // const [markdown, setMarkdown] = useState('');
  // async function fetchMarkdown() {
  //   try {
  //     const {data: {markdown}} = await axiosInstance.get(url);
  //     setMarkdown(markdown);
  //   }
  //   catch (e) {
  //     setMarkdown('# An Error Occurred');
  //   }
  // }

  // useEffect(() => {
  //   fetchMarkdown().then(() => null);
  // }, []);

  const [markdown, error] = useSSE(async () => {
    try {
      const {data: {markdown}} = await axiosInstance.get(url);
      return markdown;
    }
    catch (e) {
      return '# An Error Occurred';
    }
  }, []);

  return (
      <ReactMarkdown
        source={markdown}
        // plugins={gfm}
      />
  );
  // return (
  //   <Markdown>
  //     {markdown}
  //   </Markdown>
  // );

}

export default MarkdownFromUrl;

