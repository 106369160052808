import React from "react";
import {Box} from "grommet";
import {BlogHeading, BlogMiniPost} from "./index";

export default function BlogMiniPosts({heading, posts}) {
  return (
    <Box fill>
      {heading && <BlogHeading level={3}>{heading}</BlogHeading>}
      {posts.map(post => <BlogMiniPost key={post.id} post={post}/>)}
    </Box>
  );
}

