import React from 'react';
import {Box} from "grommet";
import {BlogHeading, BlogParagraph} from "./index";

export default function BlogAboutUs() {
  return (
    <Box fill>
      <BlogHeading level={3} margin={{ top: "large", bottom: "small" }}>About Us</BlogHeading>
      {/*<BlogParagraph size="small" margin={{ vertical: "small" }}>*/}
      {/*  Fuzing provides the Fuzing App, available on the App Store and on Google Play*/}
      {/*</BlogParagraph>*/}
      <BlogParagraph size="small">
        Fuzing provides The Fuzing App, which delivers soothing audio streams, meditations and stories,
        specifically designed to help you to relax, de-stress and sleep well.
      </BlogParagraph>
    </Box>
  );
}
