
import React from 'react';
import styled from "styled-components";
import { Anchor } from "grommet";

const BlogAnchor = styled(Anchor)`
  text-decoration: none;
  font-weight: 500;
  color: #30436d;

  &:hover {
    text-decoration: none;
    color: gray;
  }
`;

export default BlogAnchor;


