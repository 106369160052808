
import React from 'react';

export default function({appId, width, height}) {
  return (
    <a
      href={`https://play.google.com/store/apps/details?id=${appId}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`}
    >
      <img
        width={width}
        height={height}
        alt='Get it on Google Play'
        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
      />
    </a>
  );
}

