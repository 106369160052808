

import React, { useState } from 'react';
import {Form as GrommetForm, Button} from "grommet";
import ParentWidth from "../../miscellaneous/ParentWidth/ParentWidth";

import PageCard from "../../cards/PageCard";

import {Box, TextInput, TextArea} from "grommet";
import {Formik} from "formik";
import {FormikFormFastField} from "../../controls/Formik";
import Schema from "../../../../shared/Schema/Schema";

import FormErrors from "../../controls/FormErrors/FormErrors";

import {useNavigate} from "react-router";

import axiosInstance from "../../../axios";

import options from "../../../options";
import FormSubmitButtonIcon from "../../icons/FormSubmitButtonIcon";
import styled from "styled-components";

const schema = new Schema({
  name: {
    // caster: v => v,
    validator: (value, values, options) => value.length > 3 ? null : "Please Enter Your Name",
  },
  email: {
    validator: (value, values, options) =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ? null :
          "Please enter a valid email",
  },
  message: {
    validator: (value, values, options) => value.length > 10 ? null : "Message should be 10 characters or longer",
  }
}, {
  errorGenerator: error => <ParentWidth>{error}</ParentWidth>,
  stripUnknown: true,
  // custom options
  // language: "fr",
});


const initialValues = {
  name: '',
  email: '',
  message: '',
};



const ContactUsForm = ({setBusyOverlay, ...rest}) => {

  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState(null);

  const handleSubmit = async (values, {setSubmitting, resetForm}) => {
    // console.log("values is", values);
    // console.log("Validate returns", await schema.validate(values));

    const castValues = schema.cast(values);
    // console.log(values, "becomes", castValues);

    //
    //
    try {
      setBusyOverlay(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      const {data: {error}} = await axiosInstance.post(`${options.apiPrefix}/contact-us`, castValues);
      if (error) {
        setFormErrors([error.message]);
      } else {
        setFormErrors(['Message Sent']);
      }
    }
    catch (e) {
      setFormErrors(["Something went wrong"]);
      navigate('/');
    }
    finally {
      setBusyOverlay(false);
    }

    setSubmitting(false);

    // resetForm(values);
  };

  return (
    <PageCard
      heading="Contact Us"
      {...rest}
    >
      {
        (props) => <Formik
          initialValues={initialValues}
          validate={values => schema.validate(values)}
          onSubmit={handleSubmit}
        >
          {
            (props) => {
              const {dirty, isSubmitting, isValid, handleSubmit} = props;

              function resetFormErrorsInterceptor(e) { setFormErrors(null); return e; }

              return (
                <GrommetForm onSubmit={handleSubmit}>
                  {/*<Box flex direction="column" width="large">*/}
                  <Box flex direction="column" margin="small">
                    <FormikFormFastField
                      name="name"
                      label="Name"
                      placeholder="Name"
                      // help={<ParentWidth>Please type your first name and do a good job with it - so that we can fix things and all</ParentWidth>}
                      onChangeInterceptor={resetFormErrorsInterceptor}
                      maxLength={60}
                      size="large"
                      autoFocus
                      component={TextInput}
                    />

                    <FormikFormFastField
                      name="email"
                      label="Email"
                      placeholder="Email"
                      type="email"
                      // help={<ParentWidth>Please type your first name and do a good job with it - so that we can fix things and all</ParentWidth>}
                      onChangeInterceptor={resetFormErrorsInterceptor}
                      maxLength={60}
                      size="large"
                      component={TextInput}
                    />

                    <FormikFormFastField
                      name="message"
                      label="Message"
                      placeholder="Message"
                      // help={<ParentWidth>Please type your first name and do a good job with it - so that we can fix things and all</ParentWidth>}
                      onChangeInterceptor={resetFormErrorsInterceptor}
                      maxLength={2000}
                      rows={10}
                      size="large"
                      component={TextArea}
                    />

                    <Box alignSelf="center" flex={{ grow: 1 }}>
                      <Button
                        primary
                        type="submit"
                        focusIndicator={false}
                        disabled={!dirty || isSubmitting || !isValid}
                        label="Submit"
                        icon={<FormSubmitButtonIcon/>}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <FormErrors errors={formErrors} />
                  </Box>

                </GrommetForm>
              );
            }
          }
        </Formik>
      }

    </PageCard>
  );
};

export default ContactUsForm;

