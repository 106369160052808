



import React, {useContext, useEffect, useState} from "react";
import {Box, ResponsiveContext, Button, Text, Image, Heading, Paragraph, ThemeContext} from "grommet";
import {Helmet} from "react-helmet-async";


import options from "../../../options";
import { ReactComponent as CloudMoonStarsSvg } from "../../../images/svgs/cloud-moon-stars.svg";
import {ReactComponent as IconZZZs} from "../../../images/home-page/icon-zzzs.svg";

import AppStoreBadge from "../../../components/app-store-badges/AppStoreBadge";
import PlayStoreBadge from "../../../components/app-store-badges/PlayStoreBadge";
import axiosInstance from "../../../axios";
import {useParams} from "react-router";
import BusyOverlay from "../../../components/BusyOverlay";
import styled from "styled-components";


const BoxWithDropShadow = styled(Box)`
  box-shadow: 0px 4px 8px rgba(0,0,255, 0.25);
`;



export default function DownloadPage(props) {
  return <BusyOverlay>
    {
      ({setBusyOverlay, ...rest}) =>
        <Box margin="small">
          <Helmet>
            <title>Fuzing - Download the App</title>
            <meta name="description" content="Fuzing - Download the App Now.  Totally Free with No Ads!"/>
          </Helmet>

          <DownloadPageWithBusyOverlay setBusyOverlay={setBusyOverlay}/>
        </Box>
    }
  </BusyOverlay>
}


function DownloadPageWithBusyOverlay() {
  return (
    <Box
      background="neutral-1"
    >
      <BoxWithDropShadow
        background="accent-1"
      >
        <Box height="100px"/>

        <Box align="center" margin="medium">
          <Heading level={2} margin={{vertical: 'medium'}}>Start Your Journey</Heading>
          <CloudMoonStarsSvg width="200px"/>
        </Box>


      </BoxWithDropShadow>


      <Box align="center">
        <Heading level={2} margin={{vertical: 'large'}}>Get The App</Heading>
        <Box direction="row" align="start">
          <Heading textAlign="center" level={2} margin={{ vertical: "large", left: "large"}}>Along With Less Stress and a Good Night's Sleep</Heading>
          <Box width="48px" margin={{right: "large"}}>
            <IconZZZs width="100%"/>
          </Box>
        </Box>

        <Paragraph size="xxlarge">
          Totally Free, with No Ads and No In-App purchases!
        </Paragraph>

        <div>
          <AppStoreBadge
            width={300}
            height={150}
            appId={options.appleAppId}
          />
        </div>
        <div>
          <PlayStoreBadge
            width={350}
            height={150}
            appId={options.googleAppId}
          />
        </div>
      </Box>



    </Box>
  );
}


