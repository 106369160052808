

import React from 'react';

import {Send} from "grommet-icons";

export default function FormSubmitButtonIcon(props) {
  return <Send {...props} color="white"/>
}

