import React from 'react';

import {
  //Switch,
  Route,
  Routes,
} from "react-router-dom";

import CMSBlogCategoryPage from "../pages/cms/BlogCategoryPage";
import NotFoundPage from "../pages/NotFoundPage";

export default function CMSBlogCategoryRoutes(props) {
  return (
    <Routes>
      <Route path="/:slug/:page" element={<CMSBlogCategoryPage/>}/>
      <Route path="/:slug" element={<CMSBlogCategoryPage/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  );
}

