
import React from "react";
import {FastField, Field, getIn} from "formik";
import {FormField} from "grommet";
import FakeSyntheticEvent from "../../../utilities/FakeSyntheticEvent";
const eventInterceptor = (eventHandler, eventInterceptor) => event => eventInterceptor ? eventHandler(eventInterceptor(event)) : eventHandler(event);

// The event we receive could be a real DOM event, a React SyntheticEvent, or some other value thingey.... in any
//      case, make sure that the event has the name of the field on it.
const eventNameInserter = (name, handler) => event => {
  if (!event)
    return handler(event);
  if (!event.target)
    return handler(new FakeSyntheticEvent({
      name,
      ...event,
    }));
  if (!event.target.name)
    event.target.name = name;
  return handler(event);
}

export const formikToGrommetProps = ({ field, form, disabled, onChangeInterceptor, onBlurInterceptor, ...rest }) => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...rest,
    ...field,
    onChange: eventNameInserter(name, eventInterceptor(field.onChange || (e => e), onChangeInterceptor)),
    onBlur: eventNameInserter(name, eventInterceptor(field.onBlur || (e => e), onBlurInterceptor)),
    error: showError && fieldError,
    disabled: disabled !== undefined ? disabled : isSubmitting,
  };
};


const FormikFormFastField = ({ component, ...props }) =>  {
  return (
    <FastField {...props}>
      {(innerProps) => {
        return <FormField {...formikToGrommetProps({...props, ...innerProps})} component={component}/>
      }}
    </FastField>
  );
};
export default FormikFormFastField;
