//
// useSSE - a use Server Side Effect hook
//

import React, { createContext, useContext } from "react"

const HTTPContext = createContext({});

export function useHTTP(effect, dependencies) {
  return useContext(HTTPContext);
}

export function createBrowserHTTPContext() {
  return function BrowserHTTPContext(props) {
    return <HTTPContext.Provider value={{}}>
        {props.children}
      </HTTPContext.Provider>;
  };
}

export function createServerHTTPContext() {
  let httpContext = {};

  function ServerHTTPContext(props) {
    return <HTTPContext.Provider value={httpContext}>
        {props.children}
      </HTTPContext.Provider>;
  }

  return {
    ServerHTTPContext,
    httpContext,
  };
}



