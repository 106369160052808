
import React from 'react';
import {Box, Heading, Image, Text} from "grommet";
import {BlogAnchor} from "./index";

export default function BlogMiniPost({post}) {
  // const postDate = moment(post.postDate);

  return (
    <BlogAnchor margin={{ bottom: "medium" }} href={`/blog/${post.slug}`}>
      <Box fill direction="row" align="center">
        <Image width="100px" src={post.image3to2Url} margin={{ right: 'medium'}} alt={post.image3to2AltText}/>
        <Box>
          <Heading level={6} margin={{ bottom: "small", top: "none" }}>{post.title}</Heading>
          {/*<Text>{postDate.format('MMM DD')}</Text>*/}
          <Text>{new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' }).format(Date.parse(post.postDate))}</Text>
        </Box>
      </Box>
    </BlogAnchor>
  );
}
