

import React from 'react';

import {
  Box,
  Heading,
  Text,
  Paragraph,
} from "grommet";

import ContactUsForm from "../components/forms/users/ContactUsForm";
import {Helmet} from "react-helmet-async";
import BusyOverlay from "../components/BusyOverlay";
import styled from "styled-components";

const BoxWithDropShadow = styled(Box)`
  box-shadow: 0px 8px 16px rgba(0,0,255, 0.25);
`;

const AddressText = ({children, ...rest}) => <Text weight="bold" {...rest}>{children}</Text>

function ContactUsPage() {
  return (
    <BusyOverlay>
      {
        (props) =>
          <Box align="center">
            <Helmet>
              <title>Fuzing - Contact Us</title>
              <meta name="description" content="Fuzing Contact Us"/>
            </Helmet>
            <ContactUsForm setBusyOverlay={props.setBusyOverlay}/>
            <Box fill background="neutral-1">
              <BoxWithDropShadow fill background="accent-1" align="center" pad="large">
                <Heading level={2} textAlign="center"  margin={{ horizontal: "small", bottom: "small" }}>Fuzing Offices</Heading>
                <Box>
                  <Heading level={3} margin={{ bottom: "large" }}>United States</Heading>
                  <AddressText>Fuzing LLC</AddressText>
                  <AddressText>496 S. Washington St.</AddressText>
                  <AddressText>Denver, Colorado 80209</AddressText>
                  <AddressText>Phone: (720) 805-2230</AddressText>
                </Box>
              </BoxWithDropShadow>
            </Box>


          </Box>
      }
    </BusyOverlay>
  );
}

export default ContactUsPage;
