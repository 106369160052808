import React from 'react';

import {
  //Switch,
  Route,
  Routes,
} from "react-router-dom";

import CMSLandingPage from "../pages/cms/LandingPage";
import NotFoundPage from "../pages/NotFoundPage";

export default function CMSLandingRoutes(props) {
  return (
    <Routes>
      <Route path="/:slug" element={<CMSLandingPage/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  );
}

