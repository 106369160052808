import React from 'react';
import {Box, Heading} from 'grommet';
import FuzingInfoCard from "../cards/FuzingInfoCard";
import {ReactComponent as IconRelax} from "../../images/home-page/icon-relax-de-stress.svg";
import {ReactComponent as IconSleepWell} from "../../images/home-page/icon-sleep-well.svg";
import {ReactComponent as IconMindfulness} from "../../images/home-page/icon-mind.svg";
import {ReactComponent as IconZZZs} from "../../images/home-page/icon-zzzs.svg";


export default function FuzingInfoCards({children}) {
  return (
    <Box>
      <Box width={{ min: "small"}} margin="medium" align="center">
        <Box align="center">
          <Box direction="row" align="start">
            <Heading textAlign="center" level={2} margin={{ vertical: "large", left: "large"}}>Relax, De-Stress & Sleep Well</Heading>
            <Box width="48px" margin={{right: "large"}}>
              <IconZZZs width="100%"/>
            </Box>
          </Box>

          <Box direction="row" wrap={true} justify="center">
            <FuzingInfoCard
              icon={IconRelax}
              heading="Relieve Stress, Anxiety & Insomnia"
            >
              The Fuzing App delivers soothing and immersive audio streams designed specifically to help you to
              de-stress, relax, and get a restful night's sleep.
              We use scientifically proven techniques to help you with your stress, anxiety and insomnia.
            </FuzingInfoCard>


            <FuzingInfoCard
              icon={IconSleepWell}
              heading="Sleep Well"
            >
              Fuzing Sleep Streams are original guided imagery stories
              backed by a Tru-Atmosphere track, providing you with the perfect
              way to relax, de-stress, and to sleep.
              Fuzing Sleep Tales are designed to lull you gently into a
              restful sleep.  You're placed into each story where you'll
              travel to exotic places in times past, present and future.
            </FuzingInfoCard>

            <FuzingInfoCard
              icon={IconMindfulness}
              heading="Practice Mindfulness"
            >
              Learn techniques like mindfulness with our original and award winning "7 Days to Mindfulness"
              and "7 Days to Mindfulness For Sleep" series.   Bring some peace and quiet to your busy mind
              with sessions for beginners through advanced.
            </FuzingInfoCard>
          </Box>

        </Box>
      </Box>

      <Box align="center">
        {children}
      </Box>

    </Box>

  );
}
