import React from 'react';

import {ThemeProvider} from "styled-components";
import options from "./options";
import styled from "styled-components";
import Color from 'color';

//
// grommet starter at: https://github.com/grommet/grommet-starter-new-app
//

import {
  Box,
  Grommet,
} from "grommet";

import { deepMerge } from "grommet/utils";
// import { base } from "grommet";
import { grommet as grommetTheme } from "grommet/themes";

import RootRoutes from "./routes/RootRoutes";
import AppBar from "./components/AppBar";
import MainFooter from "./components/footers/MainFooter";



// import FormikSchemaForm from "./components/forms/FormikSchemaForm/FormikSchemaForm";

//
// see: https://github.com/grommet/grommet/wiki/Grommet-v2-theming-documentation
//
const customTheme = {
  animation: {
    duration: '10s',
  },
  // set our formfield props
  formField: {
    round: "small",
    border: {
      // position: "outer",
      // color: "secondary",
      side: "all",
      // style: "dashed",
      error: {
        color: "status-critical",
      },
    },
    error: {
      color: "status-critical",   // status-critical
      size: "xlarge",
      weight: 800,
    },
    help: {
      color: "green",     // dark-3
      size: "medium",
      weight: 800,
    },
    info: {
      color: "text-xweak",      // text-xweak
      size: "small",
      // weight: 800,
    },
    label: {
      color: "text",
      size: "xlarge",
      weight: 600,
    },
  },
  global: {
    // drop: {
    //   border: {
    //     radius: '4px',
    //   },
    // },
    elevation: {
      "light": {
        "none": "none",
        "xsmall": "0px 1px 2px rgba(0,0,255, 0.25)",
        "small": "0px 2px 4px rgba(0,0,255, 0.25)",
        "medium": "0px 4px 8px rgba(0,0,255, 0.25)",
        "large": "0px 8px 16px rgba(0,0,255, 0.25)",
        "xlarge": "0px 12px 24px rgba(0,0,255, 0.25)",
      },
      "dark": {
        "none": "none",
        "xsmall": "0px 2px 2px rgba(0,0,255, 0.25)",
        "small": "0px 4px 4px rgba(0,0,255, 0.25)",
        "medium": "0px 6px 8px rgba(0,0,255, 0.25)",
        "large": "0px 8px 16px rgba(0,0,255, 0.25)",
        "xlarge": "0px 12px 24px rgba(0,0,255, 0.25)",
      },
    },
    borderSize: {
      xsmall: "3px",
      small: "4px",
      medium: "4px",
      large: "6px",
      xlarge: "8px",
    },
    colors: {
      brand: options.colors.brand,
      text: options.colors.text,
      "accent-1": options.colors.banner,
      "accent-2": Color(options.colors.banner).lighten(0.05).string(),
      // "neutral-1": "blue",
      "neutral-1": "white",
      // control: {
      //   light: "white",
      //   dark: "white",
      // }
    },
    font: {
      family: 'Roboto',
      weight: 500,
    },
  },

  button: {
    color: 'white',       // color of text label
    border: {
      radius: "2em",
      width: 0,
    },
    padding: {
      vertical: '1em',
      horizontal: '1.2em',
    },
    primary: {
      color: options.grommet.button.color,
    },
    secondary: {
      color: options.grommet.button.color,
    },

    // // color: "blue",
    // // background: "yellow",
    // icon: {
    //   color: "blue",
    // },
    // active: {
    //   color: "blue",
    //   // background: "yellow",
    //   background: {
    //     color: "#ddd",
    //   },
    // }
    size: {
      small: {
        border: { radius: "1.25em" },
        pad: { horizontal: "1.25em", vertical: "0.75em" }
      },
      medium: {
        border: { radius: "1.25em" },
        pad: { horizontal: "1.25em", vertical: "0.75em" }
      },
      large: {
        border: { radius: "1.25em" },
        pad: { horizontal: "1.25em", vertical: "0.75em" }
      },
    },
  },
  heading: {
    // responsiveBreakpoint: "small",
    // font: {},
    weight: 900,
    level: {
      "1": {
        font: {
          weight: 900,
        },
        "small": {
          "size": "48px",
          height: "1em",
          // maxWidth: "30em",
        },
        medium: {
          "size": "64px",
          height: "1em",
          // maxWidth: "30em",
        },
        large: {
          "size": "80px",
          height: "1em",
          // maxWidth: "30em",
        },
      },
      "2": {
        font: {},
        small: {
          size: "28px",
          height: "1em",
          // maxWidth: "30em",
        },
        medium: {
          size: "48px",
          height: "1em",
          // maxWidth: "30em",
        },
      },
      "3": {
        font: {},
        small: {
          size: "22px",
          height: "1em",
          // maxWidth: "30em",
        },
        medium: {
          size: "36px",
          height: "1em",
          // maxWidth: "30em",
        },
      },
      "4": {
        font: {},
        small: {
          size: "20px",
          height: "1em",
          // maxWidth: "30em",
        },
        medium: {
          size: "32px",
          height: "1em",
          // maxWidth: "30em",
        },
      },
      "5": {
        font: {},
        small: {
          size: "18px",
          height: "1em",
          // maxWidth: "30em",
        },
        medium: {
          size: "26px",
          height: "1em",
          // maxWidth: "30em",
        },
      },
      "6": {
        font: {},
        small: {
          size: "16px",
          height: "1em",
          // maxWidth: "30em",
        },
        medium: {
          size: "22px",
          height: "1em",
          // maxWidth: "30em",
        },
      },
    }
  },
  paragraph: {
    // font: {
    //   family: "Roboto",
    // },
    xsmall: {
      size: '16px',
      height: '1.8em',
      maxWidth: '30em',
    },
    small: {
      size: '18px',
      height: '1.8em',
      maxWidth: '30em',
    },
    medium: {
      size: '22px',
      height: '1.8em',
      maxWidth: '30em',
    },
    large: {
      size: '24px',
      height: '1.8em',
      maxWidth: '30em',
    },
    xlarge: {
      size: '28px',
      height: '1.8em',
      maxWidth: '30em',
    },
    xxlarge: {
      size: '32px',
      height: '1.8em',
      maxWidth: '30em',
    },
  },
  text: {
    small: {
      size: "16px",
      height: "16px",
      // maxWidth: "30em",
    },
    medium: {
      size: "18px",
      height: "20px",
      // maxWidth: "30em",
    },
    large: {
      size: "24px",
      height: "24px",
      maxWidth: "30em",
    },
    // xlarge: {
    //   size: "48px",
    //   height: "20px",
    //   maxWidth: "900px",
    // },
    // xxlarge: {
    //   size: "48px",
    //   height: "20px",
    //   maxWidth: "900px",
    // },
  }
};


// const theme = _.merge(grommetTheme, customTheme);
const theme = deepMerge(grommetTheme, customTheme);
// const theme = customTheme;
// const theme = grommetTheme;

const CenterBox = styled(Box)`
  max-width: 1200px;
  margin: 0 auto;
`;

// RR6 - https://github.com/ReactTraining/react-router/blob/v6.0.0-alpha.1/docs/guides/migrating-5-to-6.md#upgrade-all-switch-elements-to-routes
function App() {
  return (
    <div className="App">
        <Grommet theme={theme} full>
          <AppBar/>
          <CenterBox>
            <RootRoutes/>
            <MainFooter/>
          </CenterBox>
        </Grommet>
    </div>
  );

}


export default App;
