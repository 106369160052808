

import React from 'react';

import {
  Box,
  Heading,
} from "grommet";

import ResetPasswordForm from "../../components/forms/users/ResetPasswordForm";
import {Helmet} from "react-helmet-async";

function ResetPasswordPage() {
  console.log("Reset Password ");
  return (
    <Box align="center">
      <Helmet>
        <title>Fuzing - Reset Password</title>
        <meta name="description" content="Fuzing Reset Password"/>
      </Helmet>

      <ResetPasswordForm/>
    </Box>
  );
}

export default ResetPasswordPage;
