
import React from 'react';
import options from "../../options";
import {
  Box,
  Heading,
  Text,
} from "grommet";


import {Helmet} from "react-helmet-async";

import MarkdownFromUrl from "../../components/miscellaneous/MarkdownFromUrl";

function AppleAppEulaPage() {
  return (
    <Box align="center">
      <Helmet>
        <title>Fuzing - Apple App End User License Agreement</title>
        <meta name="description" content="Apple App End User License Agreement"/>
      </Helmet>

      <Box margin={{ top: "100px", horizontal: "large" }}>
        <MarkdownFromUrl
          url={`${options.apiPrefix}/legal/apple-app-eula/en`}
        />
      </Box>
    </Box>
  );
}

export default AppleAppEulaPage;
