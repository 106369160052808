
import React from "react";
import styled from "styled-components";

import { Anchor, Box, Footer, Text } from "grommet";


import AppStoreBadge from "../../app-store-badges/AppStoreBadge";
import options from "../../../options";
import PlayStoreBadge from "../../app-store-badges/PlayStoreBadge";


const data = [
  [
    "App",
    ["home", "/"],
    ["contact us", "/contact-us"],
    ["blog", "/blog"],
    // ["privacy policy", "/privacy-notice"],
    // ["login", "/auth/login"],
  ],
  [
    "Legal",
    ["terms", "/terms-of-use"],
    ["privacy", "/privacy-notice"],
    ["cookies", "/cookie-policy"],
    ["apple app", "/apple-app-eula"],
    // ["security", "/sponsor"],
    // ["resource library"],
  ],
  // [
  //   "Company",
  //   ["about", "/about"],
  //   ["press", "/press"],
  //   ["jobs", "/jobs"],
  // ],
  // [
  //   "Customers",
  //   ["how to buy", "/about"],
  //   ["financial services", "/press"],
  //   ["glossary", "/jobs"],
  //   ["briefing", "/"],
  //   ["wah wah", "/jobs"],
  // ],
];


const StyledAnchor = styled(Anchor)`
  font-weight: 100;
`;

const FooterContent = () => {
  // return data.map(column => (
  //   <Box gap="small" key={column[0]} flex>
  //     <Text weight={400} size="large">
  //       {column[0]}
  //     </Text>
  //     <Box flex justify="start">
  //       {column.slice(1).map(([text, link]) => (
  //         <StyledAnchor key={text} href={link} size="medium" color="white">{text}</StyledAnchor>
  //       ))}
  //     </Box>
  //   </Box>
  // ));
  return (
    <Box width="100%">
      {/*<Box flex direction="row" wrap={true} justify="center" align="center">*/}
      <Box flex direction="row" wrap={true} justify="around">
        <Box>
          <AppStoreBadge
            width='200'
            height='100'
            appId={options.appleAppId}
          />
        </Box>
        <Box>
          <PlayStoreBadge
            width={350*2/3}
            height={150*2/3}
            appId={options.googleAppId}
          />
        </Box>
      </Box>
      <Box flex direction="row" justify="around">
        {data.map(column => (
          <Box gap="small" key={column[0]}>
            <Text weight={400} size="medium" color="white">
              {column[0]}
            </Text>
            <Box flex justify="start">
              {column.slice(1).map(([text, link]) => (
                <StyledAnchor key={text} href={link} size="medium" color="white">{text}</StyledAnchor>
              ))}
            </Box>
          </Box>
        ))}
      </Box>

    </Box>);
};


const MainFooter = () => {
  const year = '' + new Intl.DateTimeFormat('en', { year: 'numeric'}).format();

  return (
    <Box margin="small">
      <Footer background="#2e364c" pad="medium" align="start">
        <FooterContent/>
      </Footer>
      <Footer
        background="#525784"
        pad={{horizontal: "large", vertical: "small"}}
      >
        <Text size="small" color="white">
          © {year} Fuzing LLC - All rights reserved
        </Text>
      </Footer>
    </Box>
  );
};


export default MainFooter;



