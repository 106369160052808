import React from 'react';
import {Box, Heading, Paragraph, Card} from 'grommet';

export default function FuzingInfoCard({ icon: Icon, heading, children, ...rest}) {
  return (
    <Card
      align="center"
      // justify="center"
      pad={{ vertical: "large", horizontal: "large"}}
      margin="medium"
      background="accent-2"
      width={{ min: "300px", max: "500px" }}
      elevation="large"
    >
      <Box width="96px" margin={{ bottom: "large" }}>
        <Icon width="100%"/>
      </Box>
      <Box direction="column">
        <Heading textAlign="center" level={4} margin={{top: "none", bottom: "none", right: "medium"}}>
          {heading}
        </Heading>
        <Paragraph margin={{ top: "small", bottom: "large"}}>
          {children}
        </Paragraph>
      </Box>
    </Card>
  );
}

