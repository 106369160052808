

import styled, {keyframes} from "styled-components";
import {Box} from "grommet";
import React from "react";


const clockwiseAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }  
`;

const antiClockwiseAnimation = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }  
`;

const Spin = styled(({className, children, ...rest }) => (
  <Box
    className={className}
    {...rest}
  >
    {children}
  </Box>))`
  animation: ${props => props.direction === 'left' ? antiClockwiseAnimation : clockwiseAnimation} ${props => props.rate || '1s'} linear infinite;
  display: inline-block;
`;


export default Spin;
