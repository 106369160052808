
import React from 'react';
import styled from "styled-components";
import {Heading} from "grommet";

const BlogHeading = styled(Heading)`
  font-size: ${props => [1,2.8,2.2,1.6,1.4,1.2][props.level]}em;
  font-weight: lighter;
`;
export default BlogHeading;

