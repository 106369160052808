

import React from 'react';

import {
  Box,
  Heading,
} from "grommet";

import ForgotPasswordForm from "../../components/forms/users/ForgotPasswordForm";
import {Helmet} from "react-helmet-async";

function ForgotPasswordPage() {
  return (
    <Box align="center">
      <Helmet>
        <title>Fuzing - Forgot Password</title>
        <meta name="description" content="Fuzing Forgot Password"/>
      </Helmet>

      <ForgotPasswordForm/>
    </Box>
  );
}

export default ForgotPasswordPage;
