

import React from 'react';
import { Layer } from "grommet";
import { Grommet as PulseIcon } from "grommet-icons";
import Pulse from '../animations/Pulse';

export default function BusyLayer({children, ...rest}) {
  return (
    <Layer
        margin="large"
        animate={false}
        plain={true}
      >
        <Pulse rate="1s" min={0.5} max={1.5}>
          <PulseIcon size="xlarge" color="brand"/>
        </Pulse>
      </Layer>
  );
}
