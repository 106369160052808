
import React from 'react';
import options from "../../options";

import {
  Box,
  Heading,
  Text,
} from "grommet";

import {Helmet} from "react-helmet-async";

import MarkdownFromUrl from "../../components/miscellaneous/MarkdownFromUrl";

function TermsOfUsePage() {
  return (
    <Box align="center">
      <Helmet>
        <title>Fuzing - Terms of Use</title>
        <meta name="description" content="Terms of use for Fuzing"/>
      </Helmet>

      <Box margin={{ top: "100px", horizontal: "large" }}>
        <MarkdownFromUrl
          url={`${options.apiPrefix}/legal/terms-of-use/en`}
        />
      </Box>
    </Box>
  );
}

export default TermsOfUsePage;
