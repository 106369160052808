//
// useSSE - a use Server Side Effect hook
//

import React, { createContext, useContext, useState, useEffect } from "react"

const DataContext = createContext({});
const InternalContext = createContext({
  requests: [],
  resolved: false,
  current: 0,
});

export function useSSE(effect, dependencies) {
  const internalContext = useContext(InternalContext);
  let callId = internalContext.current++;
  const ctx = useContext(DataContext);

  const [data, setData] = useState(ctx[callId]?.data || null);
  const [error, setError] = useState(ctx[callId]?.error || null);

  if (!internalContext.resolved) {
    let cancel = Function.prototype;
    const effectPr = new Promise((resolve) => {
      cancel = () => {
        if (!ctx[callId]) {
          ctx[callId] = { error: { message: "timeout" }, id: callId };
        }
        resolve(callId);
      };
      return effect()
        .then((data) => {
          ctx[callId] = { data };
          resolve(callId);
        })
        .catch((error) => {
          ctx[callId] = { error };
          resolve(callId);
        });
    });

    internalContext.requests.push({
      id: callId,
      promise: effectPr,
      cancel,
    });
  }

  useEffect(() => {
    if (internalContext.resolved && !ctx[callId]) {
      effect()
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          setError(error);
        });
    }
    delete ctx[callId];
  }, dependencies);

  return [data, error];
}


export function createBrowserSSEContext(variableName = "_initialDataContext") {
  const initial = window && window[variableName] ? window[variableName] : {};
  const internalContextValue = {
    current: 0,
    resolved: true,
    requests: [],
  };

  return function BrowserDataContext(props) {
    return <InternalContext.Provider value={internalContextValue}>
      <DataContext.Provider value={initial}>
        {props.children}
      </DataContext.Provider>
    </InternalContext.Provider>;
  };
}

function wait(time) {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject({ error: "timeout" }), time);
  });
}


export function createServerSSEContext() {
  let ctx = {};
  let internalContextValue = {
    current: 0,
    resolved: false,
    requests: [],
  };

  function ServerDataContext(props) {
    return <InternalContext.Provider value={internalContextValue}>
      <DataContext.Provider value={ctx}>
        {props.children}
      </DataContext.Provider>
    </InternalContext.Provider>;
  }

  async function resolveData(timeout) {
    const effects = internalContextValue.requests.map(item => item.promise);

    if (timeout) {
      const timeoutPr = wait(timeout);
      await Promise.all(
        internalContextValue.requests.map((effect, index) => {
          return Promise.race([effect.promise, timeoutPr]).catch(() => {
            return effect.cancel();
          });
        })
      );
    } else
      await Promise.all(effects);

    internalContextValue.resolved = true;
    internalContextValue.current = 0;
    return {
      data: ctx,
      toJSON: function () {
        return this.data;
      },
      toHTML: function (variableName = "_initialDataContext") {
        return (
          `<script>
            window.${variableName} = ${JSON.stringify(this)};
          </script>`
        );
      }
    };
  }

  return {
    ServerDataContext,
    resolveData,
  };
}



