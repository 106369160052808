
import React from 'react';
import {Box, Heading, Image, Text} from "grommet";
import {BlogAnchor} from "./index";

const imageHeight = {
  default: 200,
  small: 150,
  medium: 200,
  large: 225,
};


export default function CategoryMini({category, size}) {
  // const postDate = moment(category.postDate);

  return (
    <BlogAnchor margin={{ bottom: "medium" }} href={`/blog/category/${category.slug}`}>
      <Box fill direction="column" align="center">
        <Box>
          <Image height={imageHeight[size] || imageHeight.default} src={category.bannerImageUrl} margin={{ horizontal: "small" }} alt={category.bannerImageAltText}/>
        </Box>
        <Box>
          <Heading level={6} margin={{ bottom: "small", top: "none" }}>{category.name}</Heading>
        </Box>
      </Box>
    </BlogAnchor>
  );
}
