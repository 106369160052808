


//
// this element takes on a maximum width of its parent
///
import React from "react";
import styled from "styled-components";


const ParentWidth = styled(({ className, children, ...rest }) =>
  (<div className={className} {...rest}><div className={"inner"}>{children}</div></div>))`
  display: flex;
  .inner {
    flex-grow: 1;
    width: 0;
  }
`;

export default ParentWidth;

