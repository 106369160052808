
//
// UnsubscribePage from emails
//
import React, { useState, useEffect } from 'react';
import FormErrors from "../../components/controls/FormErrors/FormErrors";
import {useLocation, useNavigate, useParams} from "react-router";
import axiosInstance from "../../axios";
import {Box, Heading, Text} from "grommet";
import {Helmet} from "react-helmet-async";
import options from "../../options";

function UnsubscribePage() {
  return (
    <Box align="center">
      <Heading>Unsubscribe</Heading>
      <UnsubscribeSubPage/>
    </Box>
  );
}

export default UnsubscribePage;


const UnsubscribeSubPage = () => {

  console.log("unsubscribe page");
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  console.log("Params is ", params);
  console.log("Navigate is ", navigate);
  console.log("Location is ", location);

  const [formErrors, setFormErrors] = useState(null);

  const urlArgs = location.search.substr(1).split('&').reduce((l, r) => {
    const x = r.split('=');
    return { ...l, [x[0]]: x[1]};
  }, {});

  async function unsubscribe() {
    try {
      const {data: {error}} = await axiosInstance.post(`${options.apiPrefix}/auth/unsubscribe`, {
        email: urlArgs.email,
        token: urlArgs.token,
      });
      if (error) {
        setFormErrors([error.message]);
      } else {
        // navigate('/');
        setFormErrors([`The email address '${urlArgs.email}' has been unsubscribed from future mailings`]);
      }
    }
    catch (e) {
      setFormErrors(["Something went wrong"]);
      // navigate('/');
    }
  }

  useEffect(() => {
    unsubscribe().then(() => {});
  }, []);


  return (
      <Box flex direction="column" width="large">
        <Helmet>
          <title>Fuzing - Unsubscribe From Emails</title>
          <meta name="description" content="Fuzing Unsubscribe from Emails"/>
        </Helmet>

        <Box>
          {
            formErrors ? <FormErrors errors={formErrors} /> : <Text>{urlArgs.email} has been unsubscribed from future emails - Thank you!</Text>
          }
        </Box>
      </Box>
  );

};

