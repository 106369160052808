import React from 'react';
import {Box, Heading, Text, Paragraph, Card, Avatar, Image } from 'grommet';
import {Star, StarHalf} from "grommet-icons";

function StarRating({rating, ...rest}) {
  const starSize = "24px";
  const wholeStars = Math.floor(rating);
  const halfStar = (rating - wholeStars) > 0.25;
  return <Box direction="row" align="start" {...rest}>
    {Array(wholeStars).fill(true).map((x, index) => (<Star key={index} size={starSize} color="red"/>))}
    {halfStar && <StarHalf size={starSize} color="red"/>}
  </Box>
}

// const color = "lightyellow";
// const color = "#f8de7e";
const color = "white";

export default function RatingCard({rating, raterName, ratingText, imageUrl, ...rest}) {
  return (
    <Card
      align="center"
      // justify="center"
      pad={{ vertical: "large", horizontal: "medium"}}
      margin="medium"
      background={color}
      width={{ min: "400px", max: "400px" }}
      height={{ min: "small" }}
      elevation="large"
    >
      <Box direction="column" align="center">
        {/*<Heading level={6}>{raterName}</Heading>*/}
        <Image src={imageUrl} width="140px" />
        {/*<Avatar src={imageUrl} size="180px" />*/}
        <StarRating rating={rating}/>
        <Paragraph size="small" margin={{ vertical: "medium"}}>
          {ratingText}
        </Paragraph>
        <Text size="medium" weight={900}>{raterName}</Text>
      </Box>
    </Card>
  );
}

