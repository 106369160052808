

import styled, {keyframes} from "styled-components";
import {Box} from "grommet";
import React from "react";


const clockwiseAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }  
`;

const antiClockwiseAnimation = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }  
`;


const pulseAnimation = (min = 0.75, max = 1.0) => keyframes`
  0% {
    transform: scale(${min});
  }

  70% {
    transform: scale(${max});
  }

  100% {
    transform: scale(${min});
  }
`;

const Pulse = styled(({className, children, ...rest }) => (
  <Box
    className={className}
    {...rest}
  >
    {children}
  </Box>))`
  // animation: ${pulseAnimation()} ${props => props.rate || '1s'} ease-in-out infinite;
  animation: ${props => pulseAnimation(props.min, props.max)} ${props => props.rate || '1s'} ease-in-out infinite;
  display: inline-block;
`;


export default Pulse;
