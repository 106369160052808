


//
//
// WARNING - if you're changing this information then you should
//   probably be changing what appears on the "RedeemedPage" as well
//
//
import React from "react";
import {Box, Button, Text, Image, Heading, Paragraph} from "grommet";
import styled from 'styled-components';
import options from "../../options";
import { ReactComponent as CloudMoonStarsSvg } from "../../images/svgs/cloud-moon-stars.svg";
// import cloudMoonStarsSvgUrl from "../../images/svgs/cloud-moon-stars.svg";
import FuzingInfoCards from "./FuzingInfoCards";
import FuzingRatingCards from "./FuzingRatingCards";

const BoxWithDropShadow = styled(Box)`
  box-shadow: 0px 8px 16px rgba(0,0,255, 0.25);
`;

export default function FuzingBrandContent({ children, displayTrialButton, displayFuzingInfo, promotionDetails, ...rest }) {
  return (
    <Box
      background="neutral-1"
    >
      <BoxWithDropShadow
        background="accent-1"
      >
        <Box height="100px"/>
        <Box align="center" margin={{ horizontal: "medium", top: "medium", bottom: "large"}}>
          { promotionDetails ?
            <Box>
              <Heading textAlign="center" level={1} margin={{vertical: "small"}}>
                Free {promotionDetails.daysToCredit} day All-Access Pass
              </Heading>

              <Heading textAlign="center" level={2} margin={{vertical: 'medium'}}>
                Compliments of
              </Heading>

              <Heading textAlign="center" level={1} size="large" margin="medium">
                { promotionDetails.complimentsOf }
              </Heading>
            </Box>
            :
            <Box>
              <Heading textAlign="center" size="large" level={1} margin={{vertical: 'small'}}>The Fuzing App</Heading>
              {/*<Heading textAlign="center" size="large" level={2} margin={{vertical: 'small'}}>Like a Spa For Your Mind</Heading>*/}
            </Box>
          }

          <CloudMoonStarsSvg width="200px"/>
          {/*<Image width="200px" src={cloudMoonStarsSvgUrl}/>*/}

          <Paragraph fill size="medium" textAlign="center" responsive margin={{top: "small", bottom: "large", horizontal: "medium"}}>
            The Fuzing App will help you to Relax, De-Stress and Sleep Well.  The App provides adult sleep tales, award winning meditations, relaxing melodies, nature sounds and music - totally Free with no ads or in-app purchases.
            Take this important step, and let your journey to better sleep and a more relaxed & balanced life begin
          </Paragraph>

          {displayTrialButton &&
          <Box>
            <Heading textAlign="center" level={2}>Start Your Journey</Heading>
            <Button
              margin={{ top: "small" }}
              // href={`/r/${options.trial14DayCode}?ref=home`}
              href={'/download'}
              primary
              size="large"
              // had to do label this way because textAlign not set on the label text
              // label="Free 14 Day All-Access Pass"
              label={<Box><Text size="large" textAlign="center">Totally Free with No Ads</Text></Box>}
            />
          </Box>
          }
        </Box>
        <Box margin={{ bottom: "medium" }}/>
      </BoxWithDropShadow>

      { displayFuzingInfo && <Box>
        <FuzingInfoCards/>
        <FuzingRatingCards/>
      </Box> }

      {children}
    </Box>
  );
}


