
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import options from "../../options";

import { useSSE } from "../../custom-hooks/useSSE";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {Markdown} from "grommet";

import NotFoundPage from "../NotFoundPage";

import {useParams} from "react-router";

import {
  Box,
  Heading,
  Image,
} from "grommet";


import {Helmet} from "react-helmet-async";

import axiosInstance from "../../axios";

const StyledReactMarkdown = styled(ReactMarkdown)`
  //p {
  //  font-size: 30px;
  //  line-height: 32px;
  //}
  //li {
  //  font-size: 30px;
  //  line-height: 32px;
  //}
`;

const StyledMarkdown = styled(Markdown)`
  h1 {
    font-size: 3em;
  }
`;


const SomeImage = styled.img`
  width: 50%;
`;


function LandingPage() {
  const params = useParams();

  const [landingPageData, error] = useSSE(async () => {
    try {
      const {data: {landingPage}} = await axiosInstance.get(`${options.apiPrefix}/cms/landing-page/${params.slug}`);
      return landingPage;
    }
    catch (e) {
      return null;
    }
  }, []);


  console.log("landing page data is", landingPageData);

  return (
    !landingPageData ? <NotFoundPage/> :
      <Box>
        <Helmet>
          <title>{landingPageData.htmlTitle || ''}</title>
          <meta name="description" content={landingPageData.htmlDescription || ''}/>
        </Helmet>

        <Box margin={{top: "100px", horizontal: "large"}}>
          <Box>
            {/*<Box direction="row-responsive">*/}
            {/*  <Box fill>*/}
            {/*    <Heading level={1}>{landingPageData.title}</Heading>*/}
            {/*    <Heading level={3} fill>{landingPageData.subTitle}</Heading>*/}
            {/*  </Box>*/}
            {/*  <Box fill>*/}
            {/*    <Image src={landingPageData.bannerImage}/>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            <Box fill>
              {/*<StyledReactMarkdown*/}
              {/*  source={landingPageData.post || ''}*/}
              {/*  plugins={gfm}*/}
              {/*  renderers={{*/}
              {/*    image: SomeImage*/}
              {/*  }}*/}
              {/*/>*/}
              <StyledMarkdown>
                {landingPageData.post || ''}
              </StyledMarkdown>
            </Box>
          </Box>
        </Box>
      </Box>
  );
}

export default LandingPage;
